import React, {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import ReactPlayer from 'react-player'
import {Helmet} from "react-helmet";

function View(){
    const { code } = useParams();
    const [copied, setCopied] = useState(false);
    const [step, setStep] = useState(3);
    const [result, setResult] = useState(false);
    const [videoReady, setVideoReady] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [error, setError] = useState(null);

    useEffect(()=>{
        getCongratulation();
    }, []);

    const getCongratulation = async() => {
        await fetch(process.env.REACT_APP_API_PROXY + 'getCongratulation?code=' + code, {
            method: "GET",
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                setVideoUrl(response.data.link);
            }else{
                setError(response.error);
            }
        });
    }

    if(window.matchMedia("(min-width: 1024px)").matches){
        return(
            <div className="sc__constructor desk-only">
                <div className="container d-flex justify-content-center">
                    <div className="sc__promo-ct">
                        <Link to="/" className="logo">
                            <img src={require("../assets/img/logo.png")}/>
                        </Link>
                        <div className="sc__header d-flex justify-content-center">
                            <div className="sc__title-wrap">
                                <div className="sc__title gradient-text text-center">
                                    <span>Сайт доступен</span> <br/>
                                    для просмотра с мобильного устройства
                                </div>

                                <div className="star__light-decor star__light-decor--1"/>
                                <div className="star__light-decor star__light-decor--2"/>
                            </div>
                        </div>
                        <div className="sc__descr text-center">
                            для перехода отсканируйте QR-код <br/>
                            камерой вашего смартфона
                        </div>
                        <div className="lock__phone-box">
                            <div className="lock__phone-decor"/>
                            <div className="lock__qr">
                                <img src={require("../assets/img/lock-qr.png")}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <>
            <Helmet>
                <title>Вас поздравили с Новым Годом</title>
                <meta name="title" content="Вас поздравили с Новым Годом"/>
                <meta property="og:title" content="Вас поздравили с Новым Годом"/>
                <meta property="og:image" content={process.env.REACT_APP_SHARING_URL + 'sharing-invite.png'}/>
                <meta name="twitter:image" content={process.env.REACT_APP_SHARING_URL + 'sharing-invite.png'}/>
            </Helmet>
            <div className="cnst__box">
                <div className="cnst__rotate-box d-flex align-items-center justify-content-center fadeIn animated">
                    <div className="cnst__rotate-decor-1"/>
                    <div className="cnst__rotate-decor-2"/>
                    <div className="cnst__rotate-decor-3"/>
                    <div className="cnst__rotate-ct">
                        <div className="cnst__rotate-img">
                            <img src={require("../assets/img/rotate-img.svg").default}/>
                        </div>
                        <div className="cnst__rotate-title text-center">
                            Поверните устройство <br/>
                            вертикально
                        </div>
                    </div>
                </div>
                <a className="cnst__logo">
                    <img src={require("../assets/img/logo.png")}/>
                </a>
                <div id="cnst-bl"
                     className={'cnst__bl d-flex flex-wrap justify-content-between'
                         + (step === 0 ? ' align-content-between' : '')
                         + ((step === 1 || step === 3) ? ' align-content-start' : '')}
                     data-step={step}
                >
                    {!error && (
                        <>
                            {result && (
                                <div className="box fadeIn animated">
                                    <div className="cnst__box-header">
                                        <div className="d-flex justify-content-center">
                                            <div className="sc__title-wrap title--5">
                                                <div className="cnst__box-title gradient-text fadeIn animated">
                                                    Ваше <br/>
                                                    <span>поздравление</span>
                                                </div>
                                                <div className="star__light-decor"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className={'video__box-wrap fadeIn animated' + (result ? ' finished' : '') + (!videoReady ? ' loading' : '')}>
                                <div className="video__box video__box--result">
                                    <ReactPlayer
                                        url={videoUrl}
                                        playing
                                        onReady={()=>{
                                            setVideoReady(true);
                                        }}
                                        playsinline
                                        muted
                                        onEnded={()=>{
                                            if(code) {
                                                setResult(true);
                                            }else{

                                            }
                                        }}
                                    />
                                </div>
                                {!videoReady && (
                                    <div className="loader--inline d-flex align-items-center fadeIn animated">
                                        <img src={require("../assets/img/loader-wh.svg").default}/>
                                    </div>
                                )}
                            </div>

                            {result && (
                                <div className="video__result-bg fadeIn animated">
                                    <img src={require("../assets/img/result-bg.jpg")}/>
                                </div>
                            )}

                            {result && (
                                <div className="result__actions fadeIn animated">
                                    <div className="btn__grid btn__grid--1">
                                        <a target="_blank"
                                           download
                                           href={videoUrl}
                                           className="btn__promo btn__promo--dark d-flex align-items-center justify-content-center">
                                        <span className="gradient-text text-center">
                                            Сохранить видео
                                        </span>
                                        </a>
                                    </div>
                                    <div className="box__back d-flex justify-content-center">
                                        <Link to="/" className="box__back-btn d-flex align-items-center">
                                            <i className="icon__back"/>
                                            <span className="gradient-text">на главную</span>
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {error && (
                        <>
                            <div className="box fadeIn animated">
                                <div className="cnst__box-header">
                                    <div className="d-flex justify-content-center">
                                        <div className="sc__title-wrap title--5">
                                            <div className="cnst__box-title gradient-text fadeIn animated">
                                                Упс!
                                            </div>
                                            <div className="star__light-decor"/>
                                        </div>
                                    </div>
                                    <div className="cnst__box-descr text-center">
                                        {error ?? ''}
                                    </div>
                                </div>
                                <div className="box__back d-flex justify-content-center">
                                    <Link to="/" className="box__back-btn d-flex align-items-center">
                                        <i className="icon__back"/>
                                        <span className="gradient-text">Назад</span>
                                    </Link>
                                </div>
                            </div>
                        </>
                    )}

                </div>
            </div>
        </>
    )
}

export default View;
