import axios from 'axios'

const baseUrl = process.env.REACT_APP_API_PROXY;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': 'Basic dGVzdGVyOjVFM2cyM1loQlJ0dThPZmE='
  },
});

export default axiosInstance