function Test(){
    const shareVideo = async(title, url, video_url) => {
        const response = await fetch(video_url);
        const buffer = await response.blob();
        console.log(buffer);
        const video = new File([buffer], "greeting.mp4", { type: "video/mp4" });
        const files = [video];
        await navigator
            .share({
                files,
                text: title,
            })
            .then(() => alert("Shared successfully"))
            .catch((error) => alert("Sharing failed:", error));
    }

    return(
        <div onClick={()=>{
            shareVideo(`Волшебное новогоднее поздравление по ссылке: https://korkunov-4-novyy-god-konstruktor.mnbvcx.ru/view/46137025`, process.env.REACT_APP_SHARING_URL, 'https://generator.mnbvcx.ru/ny_out/155_9_10_6.mp4')
        }} className="btn-ct d-flex justify-content-center align-items-center">
            Share test video
        </div>
    )
}
export default Test